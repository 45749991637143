<template>
  <div>
    <!-------- START - Mobile Menu List ---------->
    <div class="jaze-mobile-menu" :style="mobileShowVal ? 'display:flex':'display:none'">
      <div class="inner-menu" style="width: 100%;">
        <div class="toggleShow" @click="viewTogglefn('dashboard', 0);">
          <div class="mobile-menu-header" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Dashboard==true">
            <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon jazeIcon--navigation">
              <g stroke="none" stroke-width="0" fill="none" fill-rule="evenodd">
                <path d="M9.66693018,14.1138963 L7.03831809,10.0661886 C6.89948924,9.85241094 6.96024727,9.56656687 7.17402495,9.42773803 L7.23853807,9.38584272 C7.45231574,9.24701387 7.73815981,9.3077719 7.87698865,9.52154958 L10.4254913,13.4458995 C10.8831621,13.1631617 11.4225268,13 12,13 C13.6568542,13 15,14.3431458 15,16 C15,17.6568542 13.6568542,19 12,19 C10.3431458,19 9,17.6568542 9,16 C9,15.2854173 9.2498386,14.6291875 9.66693018,14.1138963 Z M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M4.32913966,9.72152328 C5.31030618,6.41324599 8.37333119,4 12,4 C15.6266688,4 18.6896938,6.41324599 19.6708603,9.72152328 L18.6755694,9.8874051 C17.7797052,7.05393654 15.1297139,5 12,5 C8.8702861,5 6.22029479,7.05393654 5.32443059,9.8874051 L4.32913966,9.72152328 Z M12,18 C13.1045695,18 14,17.1045695 14,16 C14,14.8954305 13.1045695,14 12,14 C10.8954305,14 10,14.8954305 10,16 C10,17.1045695 10.8954305,18 12,18 Z" fill="currentColor"></path>
              </g>
            </svg>
            &nbsp;
            <span>Dashboard</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[0] == "dashboard")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Dashboard==true" class="appFieldset--quiet toggleContent" :style='(toggleData[0] == "dashboard")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li title="Dashboard" @click="$router.push({path: '/dashboard'}),sideBarShow()">Dashboard
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('franchise', 1);" v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing !=undefined && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing != '' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no') || (headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')">
          <div class="mobile-menu-header" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.view_user_revenue==true || headerMenuDetails.permissions.FranchiseManagement==true)">
            <span class="icon--medium align jaze-icon--network"></span>
            &nbsp;
            <span>Franchise</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[1] == "franchise")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[1] == "franchise")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.view_user_revenue==true && headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing !=undefined && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing != '' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')" title="View Revenue" @click="$router.push({path: '/franchise_management/view_user_revenue'})">View Revenue
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.view_user_revenue==true && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && (headerMenuDetails.Auth.Dashboard.franchiseFlow == 2 || headerMenuDetails.Auth.Dashboard.franchiseFlow == 3 || headerMenuDetails.Auth.Dashboard.franchiseFlow == 4)&& 0">
                <a href="/franchise_management/revenue_details">
                  Revenue Details
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.FranchiseManagement==true && headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no'" title="Franchise Management" @click="$router.push({path: '/franchise_management'})">Franchise Management
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_configuration==true" title="Franchise Configuration" @click="$router.push({path: '/franchise_management/franchise_config'})">Franchise Configuration
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_logs==true" title="Transactions" @click="$router.push({path: '/franchise_management/franchise_logs'})">Transactions
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_statement==true" title="Franchise Statement" @click="$router.push({path: '/franchise_management/franchise_statement'})">Franchise Statement
              </li>
              <li v-if="headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no' && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && headerMenuDetails.Auth.Dashboard.franchiseFlow == 1" title="Franchise Payment Statement" @click="$router.push({path: '/franchise_management/franchise_payment_statement'})">Franchise Payment Statement
              </li>
              <li v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && headerMenuDetails.Auth.Dashboard.franchiseFlow == 2 && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit != undefined && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit != '' && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit == 'yes'" title="Franchise Payments" @click="$router.push({path: '/franchise_management/franchise_payments'})">Franchise Payments
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('monitoring', 2);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.active_sessions==true || headerMenuDetails.permissions.completed_sessions==true || headerMenuDetails.permissions.traffic_graphs==true)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--streaming-media"></span>
            &nbsp;
            <span>Monitoring</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[2] == "monitoring")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[2] == "monitoring")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.active_sessions==true" title="Active Sessions" @click="$router.push({path: '/monitoring/active_sessions'}),sideBarShow()">Active Sessions
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_sessions==true">
                <a href="/monitoring/completed_sessions">
                  <span class="title">Completed Sessions</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.platform=='cloud' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.router_graph_em==true && (headerMenuDetails.authAccId=='treebohotels1' || headerMenuDetails.authAccId=='gamil3')">
                <a href="/monitoring/router_graph_em">
                  <span class="title">Router Graph</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.edition=='isp' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.traffic_graphs==true">
                <a href="/monitoring/traffic_graphs">
                  <span class="title">Traffic Graphs</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('user', 3);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Users==true || headerMenuDetails.permissions.customize_vouchers==true || headerMenuDetails.permissions.search_users==true || headerMenuDetails.permissions.getProductWithIdsAndValues==true || headerMenuDetails.permissions.caf_generation==true || headerMenuDetails.permissions.phone_numbers==true)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--users" style="-webkit-text-stroke: .8px var(--color-500);"></span>
            &nbsp;
            <span v-if="headerMenuDetails.edition=='cloud'"> Vouchers </span>
            <span v-else> Users </span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[3] == "user")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[3] == "user")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.getProductWithIdsAndValues==true">
                <a v-if="headerMenuDetails.edition=='cloud'" href="/vouchers">
                  <span class="title"> Voucher Management </span>
                </a>
                <a v-else href="/users">
                  <span class="title"> User Management </span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_vouchers==true && headerMenuDetails.edition!='isp'">
                <router-link v-if="headerMenuDetails.edition=='cloud'" :to="{path:'/vouchers/customize_vouchers'}">
                  <span class="title"> Customize Vouchers </span>
                </router-link>
                <router-link v-else :to="{path:'/users/customize_vouchers'}">
                  <span class="title"> Customize Vouchers </span>
                </router-link>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.caf_generation==true && headerMenuDetails.edition=='isp'">
                <a href="/users/caf_generation">
               <span class="title">CAF Generation</span>
                </a>

              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.phone_numbers==true && headerMenuDetails.edition=='cloud'">
                <a href="/users/phone_numbers">
                  <span class="title">Phone Numbers </span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.search_users==true">

                <router-link v-if="headerMenuDetails.edition=='cloud'" :to="{ path: '/users/search_users'}" class="title">Search Vouchers</router-link>
                <a v-else href="/users/search_users">
               <span class="title">Search Users</span>
                </a>
              </li>
              <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.document_management==true && headerMenuDetails.edition=='isp' && headerMenuDetails.edition=='cloud'">
                <router-link :to="{ path: '/vouchers/document_management'}" class="title">Document Management</router-link>
              </li>
              <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.document_management==true && headerMenuDetails.edition=='isp'">
                <a  href="/users/document_management">
               <span class="title">Document Management</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('profile', 4);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.AccessProfiles==true || headerMenuDetails.permissions.Profiles==true || headerMenuDetails.permissions.bandwidth==true)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--box" style="-webkit-text-stroke: .1px var(--color-500);"></span>
            &nbsp;
            <span v-if="headerMenuDetails.edition=='isp'"> Packages </span>
            <span v-else> Profiles </span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[4] == "profile")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[4] == "profile")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Profiles==true" @click="$router.push({path: '/profiles'}),sideBarShow()">
                <span v-if="headerMenuDetails.edition=='isp'"> Usage Packages </span>
                <span v-else> Usage Profiles </span>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ott==true" @click="$router.push({path: '/profiles/ott'}),sideBarShow()">
                <span v-if="headerMenuDetails.edition=='isp'"> OTT Packages </span>
                <span v-else> OTT Profiles </span>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.iptv_profiles==true" @click="$router.push({path: '/profiles/iptv_profiles'}),sideBarShow()">
                <span v-if="headerMenuDetails.edition=='isp'"> IPTV Packages </span>
                <span v-else> IPTV Profiles </span>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.voices==true" @click="$router.push({path: '/profiles/voices'}),sideBarShow()">
                <span v-if="headerMenuDetails.edition=='isp'"> Voice Packages </span>
                <span v-else> Voice Profiles </span>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.bandwidth==true" title="Bandwidth" @click="$router.push({path: '/profiles/bandwidth'}),sideBarShow()">Bandwidth
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.fup_templateProfiles==true" title="FUP Template" @click="$router.push({path: '/profiles/fup_template'}),sideBarShow()">FUP Template
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.fup_templateProfiles==true && headerMenuDetails.edition=='cloud'">
                <a href="/profiles/voucher_access_template">
                  <span class="title">Voucher Access Template</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dynamic_vendor_attributesProfiles==true && headerMenuDetails.edition=='isp'">
                <a href="/profiles/dynamic_vendor_attributes">
                  <span class="title">Dynamic Vendor Attributes</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('billing', 5);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Billing==true || headerMenuDetails.permissions.invoice_template==true || headerMenuDetails.permissions.receipt_template==true)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--billing-app-icon" style="-webkit-text-stroke: .7px var(--color-500);"></span>
            &nbsp;
            <span>Billing</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[5] == "billing")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[5] == "billing")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.products==true" title="Products" @click="$router.push({path: '/billing/products'}),sideBarShow()">Products
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.plans==true" title="Plans" @click="$router.push({path: '/billing/plans'}),sideBarShow()">Plans
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Notifications==true" title="Notifications" @click="$router.push({path: '/billing/notifications'}),sideBarShow()">Notifications
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.invoice_template==true || headerMenuDetails.permissions.receipt_template==true || headerMenuDetails.permissions.franchise_invoice_template==true || headerMenuDetails.permissions.franchise_receipt_template==true || headerMenuDetails.permissions.caf_template==true)">
                <a>
                  <span class="title"> Template </span>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoice_template==true" title="Invoice Template" @click="$router.push({path: '/billing/invoice_template'}),sideBarShow()">Invoice Template
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.receipt_template==true" title="Receipt Template" @click="$router.push({path: '/billing/receipt_template'}),sideBarShow()">Receipt Template
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.credit_note_template==true" title="Credit Note Template" @click="$router.push({path: '/billing/credit_note_template'}),sideBarShow()">Credit Note Template
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.caf_template==true && headerMenuDetails.edition=='isp'" title="CAF Template" @click="$router.push({path: '/billing/caf_template'}),sideBarShow()">CAF Template
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ledger_template==true" title="Ledger Template" @click="$router.push({path: '/billing/ledger_template'}),sideBarShow()">Ledger Template
                  </li>
                  <li v-if="(headerMenuDetails.parentFranchiseHead=='yes' && headerMenuDetails.franchiseRevenueSharing!='' && headerMenuDetails.franchiseExclude == 'no') || (headerMenuDetails.franchiseHead=='yes' && headerMenuDetails.franchiseExclude == 'no')" title="Franchise Invoice Template" @click="$router.push({path: '/billing/franchise_invoice_template'}),sideBarShow()">Franchise Invoice Template
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ordersBilling==true" title="Orders" @click="$router.push({path: '/billing/orders'}),sideBarShow()">Orders
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoicesBilling==true" title="Invoices" @click="$router.push({path: '/billing/invoices'}),sideBarShow()">Invoices
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.proforma_invoicesBilling==true" title="Proforma Invoices" @click="$router.push({path: '/billing/proforma_invoices'}),sideBarShow()">Proforma Invoices
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoices_associated_with_payments==true" title="Invoices Associated with Payments" @click="$router.push({path: '/billing/invoices_associated_with_payments'}),sideBarShow()">Invoices Associated with Payments
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.paymentsBilling==true" title="Payments" @click="$router.push({path: '/billing/payments'}),sideBarShow()">Payments
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.credit_notesBilling==true" title="Credit Notes" @click="$router.push({path: '/billing/credit_notes'}),sideBarShow()">Credit Notes
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.voucher_based_billing==true || headerMenuDetails.permissions.schedule_renewals==true || headerMenuDetails.permissions.renewals_summary_details==true)">
                <a>
                  <span class="title"> Pay As You Go </span><i class="icon-arrow"></i>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.voucher_based_billing==true">
                    <a href="/billing/voucher_based_billing">
                      <span v-if="headerMenuDetails.edition=='cloud'" class="title"> Pay As You Go vouchers </span>
                      <span v-else class="title"> Pay As You Go users </span>
                    </a>
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.schedule_renewals==true" title="Scheduled Renewals" @click="$router.push({path: '/billing/schedule_renewals'}),sideBarShow()">Scheduled Renewals
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.graceperiod_users==true" title="Grace Period Users" @click="$router.push({path: '/billing/graceperiod_users'}),sideBarShow()">Grace Period Users
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.renewals_summary_details==true" title="Renewal Summary Details" @click="$router.push({path: '/billing/renewals_summary_details'}),sideBarShow()">Renewal Summary Details
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pay_later_prepaid==true">
                <a>
                  Postpaid/Prepaid
                </a>
                <ul class="sub-menu">
                  <li>
                    <a href="/billing/pay_later_users">
                      <span v-if="headerMenuDetails.edition=='cloud'" class="title"> Pay Later Vouchers </span>
                      <span v-else class="title"> Pay Later Users </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.static_ips_billing==true">
                <a href="/billing/static_ips_billing">
                  <span class="title">Static IP Billing </span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PinBilling==true && headerMenuDetails.comesUnderFranchiseAccount==true">
                <a>
                  <span class="title"> Pin Billing </span><i class="icon-arrow"></i>
                  <span class="selected"></span>
                </a>
                <ul class="sub-menu">
                  <li class="list-each-i">
                    <a href="/pin_billing">
                      <span class="title"> Pin Management </span>
                    </a>
                  </li>
                  <li class="list-each-i">
                    <a href="/pin_billing/pin_bunch_group">
                      <span class="title"> Pin Bunches </span>
                    </a>
                  </li>
                  <li class="list-each-i">
                    <router-link :to="{path:'/pin_billing/customize_pins'}">
                      <span class="title"> Customize Pins </span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.billingConfiguration==true" title="Billing Configuration" @click="$router.push({path: '/billing/billing_configuration'}),sideBarShow()">Billing Configuration
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.billingDiagnostics==true" title="Billing Diagnostics" @click="$router.push({path: '/billing/billing_diagnostics'}),sideBarShow()">Billing Diagnostics
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('paymentCol', 6);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.areas==true || headerMenuDetails.permissions.configuration==true || headerMenuDetails.permissions.collection_users==true || headerMenuDetails.permissions.collection_approval==true || headerMenuDetails.permissions.collection_admin_users==true || headerMenuDetails.permissions.collection_admin_approval==true || headerMenuDetails.permissions.tds_payments==true || headerMenuDetails.permissions.cheque_payments==true || headerMenuDetails.permissions.cash_payments==true || headerMenuDetails.permissions.payment_collection==true) && headerMenuDetails.edition=='isp'">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--creditcard" style="-webkit-text-stroke: 1px var(--color-500);"></span>
            &nbsp;
            <span>Payment Collection</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[6] == "paymentCol")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[6] == "paymentCol")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li>
                <a>
                  <span class="title"> Reconciliation </span><i class="icon-arrow"></i>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tds_payments==true" title="TDS Payments" @click="$router.push({path: '/billing/tds_payments'}),sideBarShow()">TDS Payments
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cheque_payments==true" title="Cheque Payments" @click="$router.push({path: '/billing/cheque_payments'}),sideBarShow()">Cheque Payments
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cash_payments==true" title="Cash Payments" @click="$router.push({path: '/billing/cash_payments'}),sideBarShow()">Cash Payments
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area' || headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'showAll') && headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.areas==true || headerMenuDetails.permissions.configuration==true || headerMenuDetails.permissions.collection_users==true || headerMenuDetails.permissions.collection_approval==true)">
                <a>
                  <span class="title"> Area Wise </span><i class="icon-arrow"></i>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.areas==true" title="Collection Areas" @click="$router.push({path: '/payments_collection/areas'}),sideBarShow()">Collection Areas
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.configuration==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Configuration" @click="$router.push({path: '/payments_collection/configuration'}),sideBarShow()">Configuration
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_users==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Collection Users" @click="$router.push({path: '/payments_collection/collection_users'}),sideBarShow()">Collection Users
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_approval==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Collection Approval" @click="$router.push({path: '/payments_collection/collection_approval'}),sideBarShow()">Collection Approval
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.building_master==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Building Master" @click="$router.push({path: '/payments_collection/building_master'}),sideBarShow()">Building Master
                  </li>
                </ul>
              </li>

              <li v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise=='' || headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'admin') && headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.collection_admin_users==true || headerMenuDetails.permissions.collection_admin_approval==true)">
                <a>
                  <span class="title"> Admin Wise </span><i class="icon-arrow"></i>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_admin_users==true" title="Collection Users" @click="$router.push({path: '/payments_collection/collection_admin_users'}),sideBarShow()">Collection Users
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_admin_approval==true" title="Collection Approval" @click="$router.push({path: '/payments_collection/collection_admin_approval'}),sideBarShow()">Collection Approval
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_collection==true" title="Payment Collection Requested Users" @click="$router.push({path: '/billing/payment_collection'}),sideBarShow()">Payment Collection Requested Users
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('lead', 7);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.get_all_leads==true || headerMenuDetails.permissions.lead_classification==true || headerMenuDetails.permissions.completed_leads==true || headerMenuDetails.permissions.dropped_leads==true || headerMenuDetails.permissions.assign_leads==true || headerMenuDetails.permissions.lead_sla==true ||  headerMenuDetails.permissions.tat_leads==true || headerMenuDetails.permissions.lead_dashboard==true) && headerMenuDetails.edition=='isp'">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--network-management"></span>
            &nbsp;
            <span>Leads</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[7] == "lead")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[7] == "lead")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_dashboard==true" title="Lead Dashboard" @click="$router.push({path: '/lead_management/lead_dashboard'}),sideBarShow()">Lead Dashboard
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.get_all_leads==true" title="Lead Management" @click="$router.push({path: '/lead_management'}),sideBarShow()">Lead Management
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_classification==true" title="Lead Configuration" @click="$router.push({path: '/lead_management/lead_classification'}),sideBarShow()">Lead Configuration
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_leads==true" title="Completed" @click="$router.push({path: '/lead_management/completed_leads'}),sideBarShow()">Completed
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tat_leads==true" title="TAT Leads" @click="$router.push({path: '/lead_management/tat_leads'}),sideBarShow()">TAT Leads
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dropped_leads==true" title="Dropped" @click="$router.push({path: '/lead_management/dropped_leads'}),sideBarShow()">Dropped
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.assign_leads==true" title="Assign Leads" @click="$router.push({path: '/lead_management/assign_leads'}),sideBarShow()">Assign Leads
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_sla==true" title="Lead SLA" @click="$router.push({path: '/lead_management/lead_sla'}),sideBarShow()">Lead SLA
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('captive', 8);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.CaptivePortal==true || headerMenuDetails.permissions.user_portal==true || headerMenuDetails.permissions.CpConfiguration==true)">
          <div class="mobile-menu-header">
            <svg viewBox="0 0 24 20" class="jazeIcon jazeIcon--navigation">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g stroke-width="0" fill-rule="evenodd" transform="translate(2.000000, 2.000000)">
                  <path d="M21,3 L21,1 L1,1 L1,16 L9,16 L9,17 L1,17 C0.44771525,17 0,16.5522847 0,16 L0,1 C0,0.44771525 0.44771525,0 1,0 L21,0 C21.5522847,-2.22044605e-16 22,0.44771525 22,1 L22,3 L21,3 Z" fill="currentColor" fill-rule="nonzero"></path>
                  <path d="M19,19 L21,19 L21,5 L13,5 L13,8 L12,8 L12,5 C12,4.44771525 12.4477153,4 13,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,19 C22,19.5522847 21.5522847,20 21,20 L19,20 L19,19 Z" fill="currentColor" fill-rule="nonzero"></path>
                  <rect x="11.5" y="9.5" width="6" height="10" rx="1" stroke-width="1" stroke="currentColor"></rect>
                </g>
                <path d="M12,19 L1,19 L1,20 C1,20.5522847 1.5372583,21 2.2,21 L12,21 L12,22 L2,22 C0.8954305,22 1.3527075e-16,21.1045695 0,20 L0,18 L12,18 L12,19 Z" fill="currentColor" fill-rule="nonzero"></path>
              </g>
            </svg>
            &nbsp;
            <span>Captive Portal Settings</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[8] == "captive")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[8] == "captive")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_networks==true && headerMenuDetails.jazePlatform == 'hardware' && 0">
                <a href="/CpConfiguration/captive_portal_networks">
                  <span class="title">Captive Portal Networks</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.CpConfiguration==true" title="Configuration" @click="$router.push({path: '/captive_portal_configuration'}),sideBarShow()">Configuration
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.enable_captive_portal==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.User.Account.parent_id == null && headerMenuDetails.chilliEnabled">
                <router-link :to="{ path: '/captive_portal_settings/enable_captive_portal'}">Captive Portal</router-link>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.blocked_macs==true" title="Blocked MACs" @click="$router.push({path: '/captive_portal_settings/blocked_macs'}),sideBarShow()">Blocked MACs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customization_wireless_instruction==true && headerMenuDetails.edition === 'campus'">
                <router-link :to="{ path: '/captive_portal_settings/wireless_instruction'}">Customize instructions to connect</router-link>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.access_control==true && headerMenuDetails.edition === 'cloud' && headerMenuDetails.unifiEnabled">
                <a href="/captive_portal_access_control">
                  <span class="title">Access Control</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_preview==true">
                <a href="/captive_portal_settings">
                  <span class="title">Customize Captive Portal</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_portal==true" title="Customize User Portal" @click="$router.push({path: '/captive_portal_settings/user_portal'}),sideBarShow()">Customize User Portal
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.targeted_ads==true" title="Targeted Ads" @click="$router.push({path: '/captive_portal_settings/targeted_ads'}),sideBarShow()">Targeted Ads
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.CaptivePortal==true" title="User Registration Config" @click="$router.push({path: '/customize_user_request_access'}),sideBarShow()">User Registration Config
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_website_logging==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.edition == 'campus'">
                <a href="/captive_portal_settings/captive_portal_website_logging">
                  <span class="title">Website Logging</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('nas', 9);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.AccessPoints==true">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--ap2" style="-webkit-text-stroke: 1px var(--color-500);"></span>
            &nbsp;
            <span v-if="headerMenuDetails.edition=='isp'"> Router Config </span>
            <span v-else> NAS Config </span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[9] == "nas")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[9] == "nas")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li>
                <a href="/routers" v-if="headerMenuDetails.edition=='isp'">
                  <span class="title">Routers</span>
                </a>
                <a href="/access_points" v-else>
                  <span class="title">NAS</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ip_ranges==true && headerMenuDetails.edition=='isp'">
                <a href="/ip_ranges">
                  <span class="title">IP Management</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cpe_device==true && headerMenuDetails.edition=='isp'">
                <a href="/cpe_device">
                  <span class="title">OLT and CPE Devices</span>
                </a>
              </li>
              <li v-else-if="headerMenuDetails.unifiEnabled==true && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.wlan_config==true">
                <a href="/access_points/wlan_config">
                  <span class="title">WLAN Profiles</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('acs', 24);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Acs==true && headerMenuDetails.acsConfig==true">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--ap2" style="-webkit-text-stroke: 1px var(--color-500);"></span>
            &nbsp;
            <span > TR-069 Config </span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[24] == "acs")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[24] == "acs")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li>
                <a href="/acs">
                  <span class="title">Dashboard</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.acs_devices==true">
                <a href="/acs/acs_devices">
                  <span class="title">Devices</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('helpdesk', 10);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Helpdesk==true && (headerMenuDetails.edition == 'isp' || headerMenuDetails.edition == 'campus')">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--headset2" style="-webkit-text-stroke: .8px var(--color-500);"></span>
            &nbsp;
            <span>HelpDesk</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[10] == "helpdesk")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[10] == "helpdesk")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_access==true" title="Dashboard" @click="$router.push({path: '/helpdesk'}),sideBarShow()">Dashboard
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Helpdesk==true" title="Tickets" @click="$router.push({path: '/helpdesk/tickets'}),sideBarShow()">Tickets
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_tickets==true" title="Completed Tickets" @click="$router.push({path: '/helpdesk/completed_tickets'}),sideBarShow()">Completed Tickets
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tat_tickets==true" title="TAT Tickets" @click="$router.push({path: '/helpdesk/tat_tickets'}),sideBarShow()">TAT Tickets
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_config==true" title="Ticket Config" @click="$router.push({path: '/helpdesk/ticket_config'}),sideBarShow()">Ticket Config
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_slas==true" title="Ticket Slas" @click="$router.push({path: '/helpdesk/ticket_slas'}),sideBarShow()">Ticket SLA
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_rules==true" title="Ticket Rules" @click="$router.push({path: '/helpdesk/ticket_rules'}),sideBarShow()">Ticket Rules
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('reporting', 11);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Reporting==true">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--document" style="-webkit-text-stroke: .4px var(--color-500);"></span>
            &nbsp;
            <span>Reporting</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[11] == "reporting")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[11] == "reporting")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li title="Reports" @click="$router.push({path: '/reporting'}),sideBarShow()">Reports
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.schedule_reporting==true" title="Schedule Report" @click="$router.push({path: '/reporting/schedule_reporting'})">Schedule Report
              </li>
              <li v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.User.Account.billing_entity_id!=undefined && headerMenuDetails.Auth.User.Account.billing_entity_id!=''">
                <a href="/reporting/bill_due_date">
                  <span class="title">Billing due date</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('analytics', 12);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Analytics==true && !(headerMenuDetails.edition == 'isp' && headerMenuDetails.currentTotalUsers > 300)">
          <div class="mobile-menu-header">
            <svg viewBox="0 0 22 23" class="jazeIcon jazeIcon--navigation">
              <g stroke="currentColor" stroke-width="0" fill="none" fill-rule="evenodd">
                <g stroke-width="1">
                  <path d="M0.573846193,19.5769231 L5.57384619,19.5769231 L5.57384619,13 C5.57384619,12.7663411 5.3844282,12.5769231 5.15076927,12.5769231 L0.996923117,12.5769231 C0.763264184,12.5769231 0.573846193,12.7663411 0.573846193,13 L0.573846193,19.5769231 Z"></path>
                  <path d="M8.57692308,19.5769231 L13.5769231,19.5769231 L13.5769231,1 C13.5769231,0.766341067 13.3875051,0.576923077 13.1538462,0.576923077 L9,0.576923077 C8.76634107,0.576923077 8.57692308,0.766341067 8.57692308,1 L8.57692308,19.5769231 Z"></path>
                  <path d="M16.5800001,19.5769231 L21.5800001,19.5769231 L21.5800001,8 C21.5800001,7.76634107 21.3905821,7.57692308 21.1569231,7.57692308 L17.003077,7.57692308 C16.7694181,7.57692308 16.5800001,7.76634107 16.5800001,8 L16.5800001,19.5769231 Z"></path>
                </g>
                <rect fill="currentColor" x="0" y="22" width="22" height="1"></rect>
              </g>
            </svg>
            &nbsp;
            <span>Analytics</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[12] == "analytics")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[12] == "analytics")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li @click="$router.push({ path: '/analytics'}),sideBarShow()">Analytics
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('announcements', 13);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Announcements==true || headerMenuDetails.permissions.offers==true)">
          <div class="mobile-menu-header">
            <svg viewBox="0 0 24 24" class="jazeIcon jazeIcon--navigation">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M1.52311199,19.5 L22.476888,19.5 L21.5153009,18.792417 C20.4416371,18.0023626 19.8076923,16.7487936 19.8076923,15.4157746 L19.8076923,8.1509434 C19.8076923,3.92759825 16.3141787,0.5 12,0.5 C7.6858213,0.5 4.19230769,3.92759825 4.19230769,8.1509434 L4.19230769,15.4157746 C4.19230769,16.7487936 3.55836286,18.0023626 2.48469914,18.792417 L1.52311199,19.5 Z" stroke="currentColor" stroke-width="1"></path>
                <path d="M9,21 L10,21 C10,22.1045695 10.8954305,23 12,23 C13.1045695,23 14,22.1045695 14,21 L15,21 C15,22.6568542 13.6568542,24 12,24 C10.3431458,24 9,22.6568542 9,21 Z" fill="currentColor" fill-rule="nonzero"></path>
              </g>
            </svg>
            &nbsp;
            <span>Announcements</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[13] == "announcements")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[13] == "announcements")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Announcements==true" title="Announcements" @click="$router.push({path: '/announcements'}),sideBarShow()">Announcements
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.offers==true" title="Triggers" @click="$router.push({path: '/announcements/triggers'}),sideBarShow()">Triggers
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.messages==true && headerMenuDetails.Auth !=undefined && ((headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount !=undefined && headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount == false) || headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount==undefined)" title="Messages to Sub Zones" @click="$router.push({path: '/announcements/messages'}),sideBarShow()">Messages to Sub Zones
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('externalIntegration', 14);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.ExternalIntegration==true || headerMenuDetails.permissions.CaptivePortal==true )">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--popout" style="-webkit-text-stroke: .7px var(--color-500);"></span>
            &nbsp;
            <span>External Integration</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[14] == "externalIntegration")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[14] == "externalIntegration")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_store==true && headerMenuDetails.edition == 'campus' && headerMenuDetails.jazePlatform == 'hardware'">
                <a href="/external_integration/user_store">
                  <span class="title">User Store</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.external_integration==true && headerMenuDetails.edition == 'cloud'" title="Social Networks" @click="$router.push({path: '/external_integration/index'}),sideBarShow()">Social Networks
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.xpress_connect==true && headerMenuDetails.edition == 'campus' && headerMenuDetails.jazePlatform == 'hardware'">
                <a @click="$router.push('/external_integration/xpress_connect')">
                  <span class="title">Jaze Xpress Setup</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pms==true && headerMenuDetails.edition != 'isp' && headerMenuDetails.jazePlatform == 'hardware'" title="PMS" @click="$router.push({path: '/external_integration/pms'}),sideBarShow()">PMS
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_sms_settings==true" title="SMS" @click="$router.push({path: '/customize_user_request_access/customize_sms_settings'}),sideBarShow()">SMS
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_email_settings==true" title="Email" @click="$router.push({path: '/customize_user_request_access/customize_email_settings'}),sideBarShow()">Email
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_gateway_settings==true" title="Payment Gateway" @click="$router.push({path: '/external_integration/payment_gateway_settings'}),sideBarShow()">Payment Gateway
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_whatsapp_settings==true" title="WhatsApp" @click="$router.push({path: '/customize_user_request_access/customize_whatsapp_settings'}),sideBarShow()">WhatsApp
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_acs_gateway==true" title="Acs Gateway" @click="$router.push({path: '/customize_user_request_access/customize_acs_gateway'}),sideBarShow()">Acs Gateway
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ftp==true" title="FTP" @click="$router.push({path: '/external_integration/ftp'}),sideBarShow()">FTP
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.googledrive==true" title="Google Drive" @click="$router.push({path: '/external_integration/googledrive'}),sideBarShow()">Google Drive
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.amazon_s3==true" title="S3" @click="$router.push({path: '/external_integration/amazon_s3'}),sideBarShow()">S3
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PaymentCollection==true" title="One Signal" @click="$router.push({path: '/external_integration/one_signal'}),sideBarShow()">One Signal
              </li>
              <li title="WebHooks" @click="$router.push({path: '/external_integration/web_hooks'}),sideBarShow()">WebHooks
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.quickbooks==true && headerMenuDetails.edition == 'isp'" title="QuickBooks" @click="$router.push({path: '/external_integration/quickbooks'}),sideBarShow()">QuickBooks
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_iptv==true && headerMenuDetails.edition == 'isp'" title="IPTV" @click="$router.push({path: '/external_integration/customize_iptv'}),sideBarShow()">IPTV
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ipphone==true && headerMenuDetails.edition == 'isp'" title="Voice Phone" @click="$router.push({path: '/external_integration/customize_ipphone'}),sideBarShow()">Voice Phone
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ott==true && headerMenuDetails.edition == 'isp'" title="OTT" @click="$router.push({path: '/external_integration/customize_ott'}),sideBarShow()">OTT
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ivr==true && headerMenuDetails.edition == 'isp'" title="IVR" @click="$router.push({path: '/external_integration/customize_ivr'}),sideBarShow()">IVR
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_enach==true && headerMenuDetails.edition == 'isp'" title="Enach" @click="$router.push({path: '/external_integration/customize_enach'}),sideBarShow()">Enach
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_aadhaarkyc==true && headerMenuDetails.edition == 'isp'" title="Aadhaar KYC" @click="$router.push({path: '/external_integration/customize_aadhaarkyc'}),sideBarShow()">Aadhaar KYC
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('inventory', 15);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Inventory==true && headerMenuDetails.edition == 'isp'">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--grid" style="-webkit-text-stroke: 1.5px var(--color-500);"></span>
            &nbsp;
            <span>Inventory</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[15] == "inventory")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[15] == "inventory")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_dashboard==true" title="Inventory Dashboard" @click="$router.push({path: '/inventory/inventory_dashboard'}),sideBarShow()">Inventory Dashboard
              </li>
              <li>
                <a>
                  <span class="title"> Stocks </span>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.stock==true" title="Stock Overview" @click="$router.push({path: '/inventory/stock'}),sideBarShow()">Stock Overview
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.stock==true" title="Stock Details" @click="$router.push({path: '/inventory/goods_in_use'}),sideBarShow()">Stock Details
                  </li>
                </ul>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_items==true" title="Items" @click="$router.push({path: '/inventory/inventory_items'}),sideBarShow()">Items
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.vendor_management==true" title="Vendor Management" @click="$router.push({path: '/inventory/vendor_management'}),sideBarShow()">Vendor Management
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.manage_locations==true" title="Manage Locations" @click="$router.push({path: '/inventory/manage_locations'}),sideBarShow()">Manage Locations
              </li>
              <li>
                <a>
                  <span class="title"> Approval </span>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_configuration==true" title="Configuration" @click="$router.push({path: '/inventory/inventory_configuration'}),sideBarShow()">Configuration
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_approval==true" title="Approval" @click="$router.push({path: '/inventory/inventory_approval'}),sideBarShow()">Approval
                  </li>
                </ul>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('task', 16);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
          <div class="mobile-menu-header">
            <svg ng-switch-when="events" viewBox="0 0 24 24" class="jazeIcon jazeIcon--navigation">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="currentColor" x="5.5" y="0.5" width="2" height="4" rx="1"></rect>
                <rect fill="currentColor" x="16.5" y="0.5" width="2" height="4" rx="1"></rect>
                <path d="M15,2.75 L8,2.75 L8,1.75 L15,1.75 L15,2.75 Z M19,2.75 L19,1.75 L22.5,1.75 C23.3284271,1.75 24,2.42157288 24,3.25 L24,6.25 C24,7.07842712 23.3284271,7.75 22.5,7.75 L2.5,7.75 C2.22385763,7.75 2,7.52614237 2,7.25 C2,6.97385763 2.22385763,6.75 2.5,6.75 L22.5,6.75 C22.7761424,6.75 23,6.52614237 23,6.25 L23,3.25 C23,2.97385763 22.7761424,2.75 22.5,2.75 L19,2.75 Z M4,2.75 L1.5,2.75 C1.22385763,2.75 1,2.97385763 1,3.25 L1,20.25 C1,21.6307119 2.11928813,22.75 3.5,22.75 L20.5,22.75 C21.8807119,22.75 23,21.6307119 23,20.25 L23,9.25 C23,8.97385763 23.2238576,8.75 23.5,8.75 C23.7761424,8.75 24,8.97385763 24,9.25 L24,20.25 C24,22.1829966 22.4329966,23.75 20.5,23.75 L3.5,23.75 C1.56700338,23.75 2.22044605e-16,22.1829966 0,20.25 L0,3.25 C-1.66533454e-16,2.42157288 0.671572875,1.75 1.5,1.75 L4,1.75 L4,2.75 Z" fill="currentColor" fill-rule="nonzero"></path>
                <polygon fill="currentColor" transform="translate(12.481112, 15.003395) rotate(-289.000000) translate(-12.481112, -15.003395) " points="13.0239691 11.0938185 13.0239691 14.3693287 16.0456781 13.2682504 16.4266305 14.4009034 13.4049215 15.4509614 15.2642122 18.1884808 14.3213551 18.9129706 12.5001596 16.1040226 10.6552315 18.9028355 9.68380289 18.1783457 11.57635 15.4509614 8.53559338 14.4009034 8.91654576 13.2682504 11.9192072 14.3897369 11.9192072 11.0938185"></polygon>
              </g>
            </svg>
            &nbsp;
            <span>Tasks</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[16] == "task")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[16] == "task")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li @click="$router.push({ path: '/tasks/assigned_tasks'}),sideBarShow()">
              Assigned Tasks
              </li>
              <li @click="$router.push({ path: '/tasks'}),sideBarShow()">
              Unclaimed Tasks
              </li>
              <li @click="$router.push({ path: '/tasks/completed_tasks'}),sideBarShow()">
              Completed Tasks
              </li>
              <li @click="$router.push({ path: '/tasks/rejected_tasks'}),sideBarShow()">
              Rejected Tasks
              </li>
              <li @click="$router.push({ path: '/tasks/approved_tasks'}),sideBarShow()">
              Approved Tasks
              </li>
              <li @click="$router.push({ path: '/tasks/pending_tasks'}),sideBarShow()">
              Pending Tasks
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('iptv', 17);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Iptv==true && headerMenuDetails.edition == 'isp'">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--desktop-monitor2" style="-webkit-text-stroke: .2px var(--color-500);"></span>
            &nbsp;
            <span>IPTV</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[17] == "iptv")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[17] == "iptv")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.set_top_box==true" title="Set Top Box" @click="$router.push({path: '/iptv/set_top_box'}),sideBarShow()">Set Top Box
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('voicePh', 18);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Ipphone==true">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--phone" style="-webkit-text-stroke: .4px var(--color-500);"></span>
            &nbsp;
            <span>Voice Phone</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[18] == "voicePh")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[18] == "voicePh")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.set_top_phone==true" @click="$router.push({ path: '/ipphone/set_top_phone'}),sideBarShow()">Voice Phone
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('log', 19);" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Logs==true || headerMenuDetails.permissions.event_data==true || headerMenuDetails.permissions.user_logs==true || headerMenuDetails.permissions.sms_logs==true || headerMenuDetails.permissions.payment_logs==true || headerMenuDetails.permissions.nat_logs==true)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--clipboard" style="-webkit-text-stroke: 1px var(--color-500);"></span>
            &nbsp;
            <span>Logs</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[19] == "log")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[19] == "log")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.event_data==true" title="Event Logs" @click="$router.push({path: '/logs/event_logs'}),sideBarShow()">Event Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_logs==true" title="User Logs" @click="$router.push({path: '/logs/user_logs'}),sideBarShow()">User Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.sms_logs==true" title="SMS Logs" @click="$router.push({path: '/logs/sms_logs'}),sideBarShow()">SMS Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.email_logs==true" title="Email Logs" @click="$router.push({path: '/logs/email_logs'}),sideBarShow()">Email Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.whatsapp_logs==true" title="Whatsapp Logs" @click="$router.push({path: '/logs/whatsapp_logs'}),sideBarShow()">Whatsapp Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_logs==true" title="Payment Logs" @click="$router.push({path: '/logs/payment_logs'}),sideBarShow()">Payment Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.nat_logs==true">
                <a href="/logs/nat_logs">
                  <span class="title">NAT logs</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.webhook_logs==true" title="Webhook logs" @click="$router.push({path: '/logs/webhook_logs'}),sideBarShow()">Webhook logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_payment_logs==true && (headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no') || ((headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')))" title="Franchise External Payment Logs" @click="$router.push({path: '/logs/franchise_payment_logs'}),sideBarShow()">Franchise External Payment Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pms_logs==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.edition != 'isp'" title="PMS Logs" @click="$router.push({path: '/logs/pms_logs'}),sideBarShow()">PMS Logs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PaymentCollection==true" title="Release notes" @click="$router.push({path: '/logs/release_notes'}),sideBarShow()">Release notes
              </li>
              <li v-if="headerMenuDetails.jazePlatform == 'hardware'">
                <a>
                  <span class="title"> DHCP Logs </span>
                </a>
                <ul class="sub-menu">
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dhcp_leases==true" title="DHCP Leases" @click="$router.push({path: '/logs/dhcp_leases'}),sideBarShow()">DHCP Leases
                  </li>
                  <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_logs==true && headerMenuDetails.chilliEnabled" title="Captive Portal Logs" @click="$router.push({path: '/logs/captive_portal_logs'}),sideBarShow()">Captive Portal Logs
                  </li>
                </ul>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('network', 20);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.NetworkSettings==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--guest-wired" style="-webkit-text-stroke: .3px var(--color-500);"></span>
            &nbsp;
            <span>Network Settings</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[20] == "network")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[20] == "network")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li @click="$router.push({ path: '/network_settings/index'}),sideBarShow()">Interface
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.vlan==true" @click="$router.push({ path: '/network_settings/vlan'}),sideBarShow()">VLANs
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dhcp==true" @click="$router.push({ path: '/network_settings/dhcp'}),sideBarShow()">DHCP
              </li>
              <li @click="$router.push({ path: '/network_settings/routing'}),sideBarShow()">Routing &amp; NAT
              </li>
              <li @click="$router.push({ path: '/network_settings/snmp'}),sideBarShow()">SNMP
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('sysTool', 21);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.SystemTools==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--tools"></span>
            &nbsp;
            <span>System Tools</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[21] == "sysTool")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[21] == "sysTool")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_tools==true" @click="$router.push({ path: '/system_tools/index'}),sideBarShow()">System Tools
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.time_settings==true && 0">
                <a href="/system_tools/time_settings">
                  <span class="title">Time Settings</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.factory_defaults==true" @click="$router.push({ path: '/system_tools/factory_defaults'}),sideBarShow()">Factory Defaults
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.backup==true" @click="$router.push({ path: '/system_tools/backup'}),sideBarShow()">Backup/Restore
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.reboot==true" @click="$router.push({ path: '/system_tools/reboot'}),sideBarShow()">Reboot System
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.halt==true" @click="$router.push({ path: '/system_tools/halt'}),sideBarShow()">Shutdown System
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.update==true" @click="$router.push({ path: '/system_tools/update'}),sideBarShow()">Update
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.certificates==true">
                <a href="/system_tools/certificates">
                  <span class="title">Certificates</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('diagnostics', 22);" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Diagnostics==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
          <div class="mobile-menu-header">
            <span class="icon--medium align jaze-icon--statistics"></span>
            &nbsp;
            <span>Diagnostics</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[22] == "diagnostics")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[22] == "diagnostics")?"display: flex" : "display:none"'>
            <ul class="sub-menu">
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.arp_table==true && 0">
                <a href="/diagnostics/arp_table">
                  <span class="title">ARP Table</span>
                </a>
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ping==true" @click="$router.push({ path: '/diagnostics/ping'}),sideBarShow()">Ping
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.trace_route==true" @click="$router.push({ path: '/diagnostics/trace_route'}),sideBarShow()">Traceroute
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_status==true" @click="$router.push({ path: '/diagnostics/system_status'}),sideBarShow()">System Status
              </li>
              <li v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_logs==true && 0">
                <a href="/diagnostics/system_logs">
                  <span class="title">System Logs</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="toggleShow" @click="viewTogglefn('payment', 23);" v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.authAccType=='full'">
          <div class="mobile-menu-header" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment==true">
            <span class="icon--medium align jaze-icon--creditcard" style="-webkit-text-stroke: .3px var(--color-500);"></span>
            &nbsp;
            <span>Payment</span>
            <span class="navIcon jaze-icon--pointer-right floatRight" :class='(toggleData[23] == "payment")? "jaze-icon--pointer-down": "jaze-icon--pointer-right"'></span>
          </div>
          <fieldset class="appFieldset--quiet toggleContent" :style='(toggleData[23] == "payment")?"display: flex" : "display:none"' v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment==true">
            <ul class="sub-menu">
              <li>
                <a href="/payment">
                  <span class="title">Payment Information</span>
                </a>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
    </div>
    <!-------- END - Mobile Menu List ---------->
    <!-------- START - Main Menu List ---------->
    <div class="jaze-topbar">
      <div class="jaze-topbar-content content-new">
        <ul class="content-inner">
          <li class="jaze-topbar-header header-new" @mouseleave="hide" @mouseover="viewHeaderMenuDatafn('users')">
            <span class="jaze-topbar-title title-new">
              Users & Packages
              <svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="top-icon">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" class="top-icon-inner"></path>
              </svg>
            </span>
            <ul class="jaze-main-menu" @mouseover="viewHeaderMenuDatafn('users')" :style='(viewHeaderMenuData == "users")? "display:flex":"display:none"'>
              <li class="jaze-main-menu-col col-new">
                <ul class="col-content col-content-new">
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Dashboard==true">
                        <li class="list-each list-head head-new list-each-i">
                          <span>Dashboard</span>
                        </li>
                        <router-link :class="{ 'jazeLink': hoverClass == '0' }" @mouseover="headerHover('0')" :to="{ path: '/dashboard'}" @click='hide'>
                          <li title="Dashboard" class="list-each-i">
                            <a class="title">Dashboard</a>
                          </li>
                        </router-link>
                      </div>
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Users==true || headerMenuDetails.permissions.customize_vouchers==true || headerMenuDetails.permissions.search_users==true || headerMenuDetails.permissions.getProductWithIdsAndValues==true || headerMenuDetails.permissions.caf_generation==true || headerMenuDetails.permissions.phone_numbers==true)">
                        <span v-if="headerMenuDetails.edition=='cloud'"> Vouchers </span>
                        <span v-else> Users </span>
                      </li>
                      <router-link :class="{ 'jazeLink': hoverClass == '1' }" @mouseover="headerHover('1')" :to="{ path: '/users'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.edition!='cloud' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.getProductWithIdsAndValues==true" title="User Management">
                          <a class="title">User Management</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '2' }" @mouseover="headerHover('2')" :to="{ path: '/vouchers'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.edition=='cloud' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.getProductWithIdsAndValues==true" title="Voucher Management">
                          <a class="title">Voucher Management</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '3' }" @mouseover="headerHover('3')" :to="{ path: '/vouchers/customize_vouchers'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_vouchers==true && headerMenuDetails.edition!='isp'">
                          <a v-if="headerMenuDetails.edition=='cloud'">
                            <span class="title"> Customize Vouchers </span>
                          </a>
                          <a v-else>
                            <span class="title"> Customize Vouchers </span>
                          </a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '4' }" @mouseover="headerHover('4')" :to="{ path: '/users/caf_generation'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.caf_generation==true && headerMenuDetails.edition=='isp'">
                          <a class="title">CAF Generation</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '5' }" @mouseover="headerHover('5')" :to="{ path: '/users/phone_numbers'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.phone_numbers==true && headerMenuDetails.edition=='cloud'">
                          <a class="title">Phone Numbers</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '6' }" @mouseover="headerHover('6')" :to="{ path: '/users/search_users'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.search_users==true">
                          <a v-if="headerMenuDetails.edition=='cloud'" class="title">Search Vouchers</a>

                          <a v-else class="title">Search Users</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '7' }" @mouseover="headerHover('7')" :to="{ path: '/vouchers/document_management'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.document_management==true && headerMenuDetails.edition=='isp' && headerMenuDetails.edition=='cloud'">
                          <a class="title">Document Management</a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '8' }" @mouseover="headerHover('8')" :to="{ path: '/users/document_management'}" @click='hide'>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.document_management==true && headerMenuDetails.edition=='isp'">
                          <a class="title">Document Management</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.AccessProfiles==true || headerMenuDetails.permissions.Profiles==true || headerMenuDetails.permissions.bandwidth==true)">
                        <span v-if="headerMenuDetails.edition=='isp'"> Packages </span>
                        <span v-else> Profiles </span>

                      </li>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '9' }" @mouseover="headerHover('9')" :to="{ path: '/profiles'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Profiles==true" title="Usage Packages">
                          <a v-if="headerMenuDetails.edition=='isp'" class="title">Usage Packages</a>
                          <a v-else class="title">Usage Profiles</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '141' }" @mouseover="headerHover('141')" :to="{ path: '/profiles/ott'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ott==true" title="OTT Packages">
                          <a v-if="headerMenuDetails.edition=='isp'" class="title">OTT Packages</a>
                          <a v-else class="title">OTT Profiles</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '142' }" @mouseover="headerHover('142')" :to="{ path: '/profiles/iptv_profiles'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.iptv_profiles==true" title="IPTV Packages">
                          <a v-if="headerMenuDetails.edition=='isp'" class="title">IPTV Packages</a>
                          <a v-else class="title">IPTV Profiles</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '143' }" @mouseover="headerHover('143')" :to="{ path: '/profiles/voices'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.voices==true" title="Voice Packages">
                          <a v-if="headerMenuDetails.edition=='isp'" class="title">Voice Packages</a>
                          <a v-else class="title">Voice Profiles</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '10' }" @mouseover="headerHover('10')" :to="{ path: '/profiles/bandwidth'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.bandwidth==true" title="Bandwidth">
                          <a class="title">Bandwidth</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '11' }" @mouseover="headerHover('11')" :to="{ path: '/profiles/fup_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.fup_templateProfiles==true" title="FUP Template">
                          <a class="title">FUP Template</a>
                        </li>
                      </router-link>
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.fup_templateProfiles==true && headerMenuDetails.edition=='cloud'">
                        <a href="/profiles/voucher_access_template">
                          <span class="title">Voucher Access Template</span>
                        </a>
                      </li>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '12' }" @mouseover="headerHover('12')" :to="{ path: '/profiles/dynamic_vendor_attributes'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dynamic_vendor_attributesProfiles==true && headerMenuDetails.edition=='isp'">
                          <a class="title">Dynamic Vendor Attributes</a>
                        </li>
                      </router-link>
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.active_sessions==true || headerMenuDetails.permissions.completed_sessions==true || headerMenuDetails.permissions.traffic_graphs==true)">
                        <span>Monitoring</span>
                      </li>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '13' }" @mouseover="headerHover('13')" :to="{ path: '/monitoring/active_sessions'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.active_sessions==true" title="Active Sessions">
                          <a class="title">Active Sessions</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '14' }" @mouseover="headerHover('14')" :to="{ path: '/monitoring/completed_sessions'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_sessions==true">
                          <a class="title">Completed Sessions</a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '15' }" @mouseover="headerHover('15')" :to="{ path: '/monitoring/router_graph_em'}">
                        <li class="list-each-i" v-if="headerMenuDetails.platform=='cloud' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.router_graph_em==true && (headerMenuDetails.authAccId=='treebohotels1' || headerMenuDetails.authAccId=='gamil3')">
                          <a href="/monitoring/router_graph_em">
                            <span class="title">Router Graph</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link @click='hide' :class="{ 'jazeLink': hoverClass == '16' }" @mouseover="headerHover('16')" :to="{ path: '/monitoring/traffic_graphs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.edition=='isp' && headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.traffic_graphs==true">
                          <a class="title">Traffic Graph</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="jaze-topbar-header header-new"  @mouseleave="hide" @mouseover="viewHeaderMenuDatafn('billing')">
            <span class="jaze-topbar-title title-new">
              <span> Billing </span>
              <span v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing !=undefined && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing != '' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no') || (headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')">
                & Franchise
              </span>
              <svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="top-icon">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" class="top-icon-inner"></path>
              </svg>
            </span>
            <ul class="jaze-main-menu" @mouseover="viewHeaderMenuDatafn('billing')" :style='(viewHeaderMenuData == "billing")? "display:flex":"display:none"'>
              <li class="jaze-main-menu-col col-new">
                <ul class="col-content col-content-new">
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Billing==true || headerMenuDetails.permissions.invoice_template==true || headerMenuDetails.permissions.receipt_template==true)">
                        <span>Billing</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '17' }" @mouseover="headerHover('17')" :to="{ path: '/billing/products'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.products==true" title="Products">
                          <a class="title">Products</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '18' }" @mouseover="headerHover('18')" :to="{ path: '/billing/plans'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.plans==true" title="Plans">
                          <a class="title">Plans</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '19' }" @mouseover="headerHover('19')" :to="{ path: '/billing/notifications'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Notifications==true" title="Notifications">
                          <a class="title">Notifications</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '20' }" @mouseover="headerHover('20')" :to="{ path: '/billing/invoice_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoice_template==true" title="Invoice Template">
                          <a class="title">Invoice Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '21' }" @mouseover="headerHover('21')" :to="{ path: '/billing/receipt_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.receipt_template==true" title="Receipt Template">
                          <a class="title">Receipt Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '22' }" @mouseover="headerHover('22')" :to="{ path: '/billing/credit_note_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.credit_note_template==true" title="Credit Note Template">
                          <a class="title">Credit Note Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '23' }" @mouseover="headerHover('23')" :to="{ path: '/billing/caf_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.caf_template==true && headerMenuDetails.edition=='isp'" title="CAF Template">
                          <a class="title">CAF Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '24' }" @mouseover="headerHover('24')" :to="{ path: '/billing/franchise_invoice_template'}">
                        <li class="list-each-i" v-if="(headerMenuDetails.parentFranchiseHead=='yes' && headerMenuDetails.franchiseRevenueSharing!='' && headerMenuDetails.franchiseExclude == 'no') || (headerMenuDetails.franchiseHead=='yes' && headerMenuDetails.franchiseExclude == 'no')" title="Franchise Invoice Template">
                          <a class="title">Franchise Invoice Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '146' }" @mouseover="headerHover('146')" :to="{ path: '/billing/ledger_template'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ledger_template==true" title="Ledger Template">
                          <a class="title">Ledger Template</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '25' }" @mouseover="headerHover('25')" :to="{ path: '/billing/orders'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ordersBilling==true" title="Orders">
                          <a class="title">Orders</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '26' }" @mouseover="headerHover('26')" :to="{ path: '/billing/invoices'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoicesBilling==true" title="Invoices">
                          <a class="title">Invoices</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '27' }" @mouseover="headerHover('27')" :to="{ path: '/billing/proforma_invoices'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.proforma_invoicesBilling==true" title="Proforma Invoices">
                          <a class="title">Proforma Invoices</a>

                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '28' }" @mouseover="headerHover('28')" :to="{ path: '/billing/invoices_associated_with_payments'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.invoices_associated_with_payments==true" title="Invoices Associated with Payments">
                          <a class="title">Invoices Associated with Payments</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '29' }" @mouseover="headerHover('29')" :to="{ path: '/billing/payments'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.paymentsBilling==true" title="Payments">
                          <a class="title">Payments</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '30' }" @mouseover="headerHover('30')" :to="{ path: '/billing/credit_notes'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.credit_notesBilling==true" title="Credit Notes">
                          <a class="title">Credit Notes</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '31' }" @mouseover="headerHover('31')" :to="{ path: '/billing/voucher_based_billing'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.voucher_based_billing==true">
                          <a v-if="headerMenuDetails.edition=='cloud'" class="title">Pay As You Go vouchers</a>
                          <a v-else class="title">Pay As You Go users</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '32' }" @mouseover="headerHover('32')" :to="{ path: '/billing/schedule_renewals'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.schedule_renewals==true" title="Scheduled Renewals">
                          <a class="title">Scheduled Renewals</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '33' }" @mouseover="headerHover('33')" :to="{ path: '/billing/graceperiod_users'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.graceperiod_users==true" title="Grace Period Users">
                          <a class="title">Grace Period Users</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '34' }" @mouseover="headerHover('34')" :to="{ path: '/billing/renewals_summary_details'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.renewals_summary_details==true" title="Renewal Summary Details">
                          <a class="title">Renewal Summary Details</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '35' }" @mouseover="headerHover('35')" :to="{ path: '/billing/pay_later_users'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pay_later_prepaid==true">
                          <a v-if="headerMenuDetails.edition=='cloud'" class="title">Pay Later Vouchers</a>
                          <a v-else class="title">Pay Later Users</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '36' }" @mouseover="headerHover('36')" :to="{ path: '/billing/static_ips_billing'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.static_ips_billing==true">
                          <a class="title">Static IP Billing</a>
                        </li>
                      </router-link>
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PinBilling==true && headerMenuDetails.comesUnderFranchiseAccount==true">
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '37' }" @mouseover="headerHover('37')" :to="{ path: '/pin_billing'}">
                          <li class="list-each-i">
                            <a class="title">Pin Management</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '137' }" @mouseover="headerHover('137')" :to="{ path: '/pin_billing/pin_bunch_group'}">
                          <li class="list-each-i">
                            <a class="title"> Pin Bunches </a>
                          </li>
                        </router-link>
                        <li class="list-each-i">
                          <router-link @click="hide" :to="{path:'/pin_billing/customize_pins'}">
                            <span class="title"> Customize Pins </span>
                          </router-link>
                        </li>
                      </div>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '38' }" @mouseover="headerHover('38')" :to="{ path: '/billing/billing_configuration'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.billingConfiguration==true" title="Billing Configuration">
                          <a class="title">Billing Configuration</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '39' }" @mouseover="headerHover('39')" :to="{ path: '/billing/billing_diagnostics'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.billingDiagnostics==true" title="Billing Diagnostics">
                          <a class="title">Billing Diagnostics</a>

                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.view_user_revenue==true || headerMenuDetails.permissions.FranchiseManagement==true)">
                        <div v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing !=undefined && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing != '' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no') || (headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')">
                          <li class="list-each list-head head-new list-each-i">
                            <span>Franchise</span>
                          </li>
                          <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.view_user_revenue==true">
                            <li class="list-each-i" v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing !=undefined && headerMenuDetails.Auth.Dashboard.franchiseRevenueSharing != '' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')" title="View Revenue" @click="$router.push({path: '/franchise_management/view_user_revenue'})">View Revenue
                            </li>
                            <li class="list-each-i" v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && (headerMenuDetails.Auth.Dashboard.franchiseFlow == 2 || headerMenuDetails.Auth.Dashboard.franchiseFlow == 3 || headerMenuDetails.Auth.Dashboard.franchiseFlow == 4)&& 0">
                              <a href="/franchise_management/revenue_details">
                                Revenue Details
                              </a>
                            </li>
                          </div>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '40' }" @mouseover="headerHover('40')" :to="{ path: '/franchise_management'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.FranchiseManagement==true && headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth !=undefined &&  headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no'" title="Franchise Management">
                              <a class="title">Franchise Management</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '41' }" @mouseover="headerHover('41')" :to="{ path: '/franchise_management/franchise_config'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_configuration==true" title="Franchise Configuration">
                              <a class="title">Franchise Configuration</a>
                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '42' }" @mouseover="headerHover('42')" :to="{ path: '/franchise_management/franchise_logs'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_logs==true" title="Transactions">
                              <a class="title">Transactions</a>
                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '43' }" @mouseover="headerHover('43')" :to="{ path: '/franchise_management/franchise_statement'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_statement==true" title="Franchise Statement">
                              <a class="title">Franchise Statement</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '44' }" @mouseover="headerHover('44')" :to="{ path: '/franchise_management/franchise_payment_statement'}">
                            <li class="list-each-i" v-if="headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no' && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && headerMenuDetails.Auth.Dashboard.franchiseFlow == 1" title="Franchise Payment Statement">
                              <a class="title">Franchise Payment Statement</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '45' }" @mouseover="headerHover('45')" :to="{ path: '/franchise_management/franchise_payments'}">
                            <li class="list-each-i" v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != undefined && headerMenuDetails.Auth.Dashboard.franchiseFlow != '' && headerMenuDetails.Auth.Dashboard.franchiseFlow == 2 && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit != undefined && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit != '' && headerMenuDetails.Auth.Dashboard.franchiseResetCreditLimit == 'yes'" title="Franchise Payments">
                              <a class="title">Franchise Payments</a>

                            </li>
                          </router-link>
                        </div>
                      </div>
                      <div v-if="headerMenuDetails.edition == 'isp'">
                        <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.areas==true || headerMenuDetails.permissions.configuration==true || headerMenuDetails.permissions.collection_users==true || headerMenuDetails.permissions.collection_approval==true || headerMenuDetails.permissions.collection_admin_users==true || headerMenuDetails.permissions.collection_admin_approval==true || headerMenuDetails.permissions.tds_payments==true || headerMenuDetails.permissions.cheque_payments==true || headerMenuDetails.permissions.cash_payments==true || headerMenuDetails.permissions.payment_collection==true) && headerMenuDetails.edition=='isp'">
                          <span>Payment Collection</span>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '46' }" @mouseover="headerHover('46')" :to="{ path: '/billing/tds_payments'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tds_payments==true" title="TDS Payments">
                            <a class="title">TDS Payments</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '47' }" @mouseover="headerHover('47')" :to="{ path: '/billing/cheque_payments'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cheque_payments==true" title="Cheque Payments">
                            <a class="title">Cheque Payments</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '48' }" @mouseover="headerHover('48')" :to="{ path: '/billing/cash_payments'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cash_payments==true" title="Cash Payments">
                            <a class="title">Cash Payments</a>

                          </li>
                        </router-link>
                        <div v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area' || headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'showAll') && headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.areas==true || headerMenuDetails.permissions.configuration==true || headerMenuDetails.permissions.collection_users==true || headerMenuDetails.permissions.collection_approval==true)">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '49' }" @mouseover="headerHover('49')" :to="{ path: '/payments_collection/areas'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.areas==true" title="Collection Areas">
                              <a class="title">Collection Areas</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '50' }" @mouseover="headerHover('50')" :to="{ path: '/payments_collection/configuration'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.configuration==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Configuration">
                              <a class="title">Configuration</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '51' }" @mouseover="headerHover('51')" :to="{ path: '/payments_collection/collection_users'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_users==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Collection Users">
                              <a class="title">Collection Users</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '52' }" @mouseover="headerHover('52')" :to="{ path: '/payments_collection/collection_approval'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_approval==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Collection Approval">
                              <a class="title">Collection Approval</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '53' }" @mouseover="headerHover('53')" :to="{ path: '/payments_collection/building_master'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.building_master==true && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'area'" title="Building Master">
                              <a class="title">Building Master</a>
                            </li>
                          </router-link>
                        </div>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '54' }" @mouseover="headerHover('54')" :to="{ path: '/billing/payment_collection'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_collection==true" title="Collections Requested">
                            <a class="title">Collections Requested</a>

                          </li>
                        </router-link>
                        <div v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'showAll'">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '147' }" @mouseover="headerHover('147')" :to="{ path: '/payments_collection/due_for_renewal'}">
                            <li class="list-each-i" title="Due For Renewal">
                              <a class="title">Due For Renewal</a>
                            </li>
                          </router-link>
                        </div>
                        <div v-if="headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise=='' || headerMenuDetails.Auth.Dashboard.paymentCollectionAdminOrAreawise == 'admin') && headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.collection_admin_users==true || headerMenuDetails.permissions.collection_admin_approval==true)">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '55' }" @mouseover="headerHover('55')" :to="{ path: '/payments_collection/collection_admin_users'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_admin_users==true" title="Collection Users">Collection Users
                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '56' }" @mouseover="headerHover('56')" :to="{ path: '/payments_collection/collection_admin_approval'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.collection_admin_approval==true" title="Collection Approval">Collection Approval
                            </li>
                          </router-link>
                        </div>
                      </div>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

          </li>
          <li class="jaze-topbar-header header-new"  @mouseleave="hide" @mouseover="viewHeaderMenuDatafn('apps')">
            <span class="jaze-topbar-title title-new">
              Apps
              <svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="top-icon">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" class="top-icon-inner"></path>
              </svg>
            </span>
            <ul class="jaze-main-menu" @mouseover="viewHeaderMenuDatafn('apps')" :style='(viewHeaderMenuData == "apps")? "display:flex":"display:none"'>
              <li class="jaze-main-menu-col col-new">
                <ul class="col-content col-content-new">
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Helpdesk==true && (headerMenuDetails.edition == 'isp' || headerMenuDetails.edition == 'campus')">
                        <li class="list-each list-head head-new list-each-i">
                          <span>HelpDesk</span>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '57' }" @mouseover="headerHover('57')" :to="{ path: '/helpdesk'}">
                          <li class="list-each-i" title="Dashboard" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_access==true">
                            <a class="title">Dashboard</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '152' }" @mouseover="headerHover('152')" :to="{ path: '/helpdesk/tickets'}">
                          <li class="list-each-i" title="Tickets" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Helpdesk==true">
                            <a class="title">Tickets</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '153' }" @mouseover="headerHover('153')" :to="{ path: '/helpdesk/completed_tickets'}">
                          <li class="list-each-i" title="Completed Tickets" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_tickets==true">
                            <a class="title">Completed Tickets</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '154' }" @mouseover="headerHover('154')" :to="{ path: '/helpdesk/tat_tickets'}">
                          <li class="list-each-i" title="TAT Tickets" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tat_tickets==true">
                            <a class="title">TAT Tickets</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '155' }" @mouseover="headerHover('155')" :to="{ path: '/helpdesk/ticket_config'}">
                          <li class="list-each-i" title="Ticket Config" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_config==true">
                            <a class="title">Ticket Config</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '156' }" @mouseover="headerHover('156')" :to="{ path: '/helpdesk/ticket_slas'}">
                          <li class="list-each-i" title="Ticket SLA" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_slas==true">
                            <a class="title">Ticket SLA</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '157' }" @mouseover="headerHover('157')" :to="{ path: '/helpdesk/ticket_rules'}">
                          <li class="list-each-i" title="Ticket Rules" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ticket_rules==true">
                            <a class="title">Ticket Rules</a>

                          </li>
                        </router-link>
                      </div>
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                        <span>Tasks</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '158' }" @mouseover="headerHover('158')" :to="{ path: '/tasks/assigned_tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Assigned Tasks</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '58' }" @mouseover="headerHover('58')" :to="{ path: '/tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Unclaimed Tasks</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '159' }" @mouseover="headerHover('159')" :to="{ path: '/tasks/completed_tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Completed Tasks</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '160' }" @mouseover="headerHover('160')" :to="{ path: '/tasks/rejected_tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Rejected Tasks</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '161' }" @mouseover="headerHover('161')" :to="{ path: '/tasks/approved_tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Approved Tasks</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '162' }" @mouseover="headerHover('162')" :to="{ path: '/tasks/pending_tasks'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Tasks==true">
                          <a>Pending Tasks</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Reporting==true">
                        <span>Reporting</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '59' }" @mouseover="headerHover('59')" :to="{ path: '/reporting'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Reporting==true" title="Reports">
                          <a class="title">Reports</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '60' }" @mouseover="headerHover('60')" :to="{ path: '/reporting/schedule_reporting'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.schedule_reporting==true" title="Schedule Report">
                          <a class="title">Schedule Report</a>

                        </li>
                      </router-link>
                      <li class="list-each-i" v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.User.Account.billing_entity_id!=undefined && headerMenuDetails.Auth.User.Account.billing_entity_id!=''">
                        <a href="/reporting/bill_due_date">
                          <span class="title">Billing due date</span>
                        </a>
                      </li>
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Announcements==true || headerMenuDetails.permissions.offers==true)">
                        <span>Announcements</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '61' }" @mouseover="headerHover('61')" :to="{ path: '/announcements'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Announcements==true" title="Announcements">
                          <a class="title">Announcements</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '62' }" @mouseover="headerHover('62')" :to="{ path: '/announcements/triggers'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.offers==true" title="Triggers">
                          <a class="title">Triggers</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '63' }" @mouseover="headerHover('63')" :to="{ path: '/announcements/messages'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.messages==true && headerMenuDetails.Auth !=undefined && ((headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount !=undefined && headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount == false) || headerMenuDetails.Auth.Dashboard.comesUnderFranchiseAccount==undefined)" title="Messages to Sub Zones">
                          <a class="title">Messages to Sub Zones</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.ExternalIntegration==true || headerMenuDetails.permissions.CaptivePortal==true )">
                        <span>External Integration</span>
                      </li>
                      <div v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.ExternalIntegration==true || headerMenuDetails.permissions.CaptivePortal==true )">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_store==true && headerMenuDetails.edition == 'campus' && headerMenuDetails.jazePlatform == 'hardware'">
                          <a href="/external_integration/user_store">
                            <span class="title">User Store</span>
                          </a>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '64' }" @mouseover="headerHover('64')" :to="{ path: '/external_integration/index'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.external_integration==true && headerMenuDetails.edition == 'cloud'" title="Social Networks">
                            <a class="title">Social Networks</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '65' }" @mouseover="headerHover('65')" :to="{ path: '/external_integration/xpress_connect'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.xpress_connect==true && headerMenuDetails.edition == 'campus' && headerMenuDetails.jazePlatform == 'hardware'">
                            <a>
                              <span class="title">Jaze Xpress Setup</span>
                            </a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '66' }" @mouseover="headerHover('66')" :to="{ path: '/external_integration/pms'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pms==true && headerMenuDetails.edition != 'isp' && headerMenuDetails.jazePlatform == 'hardware'" title="PMS">
                            <a class="title">PMS</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '67' }" @mouseover="headerHover('67')" :to="{ path: '/customize_user_request_access/customize_sms_settings'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_sms_settings==true" title="SMS">
                            <a class="title">SMS</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '68' }" @mouseover="headerHover('68')" :to="{ path: '/customize_user_request_access/customize_email_settings'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_email_settings==true" title="Email">
                            <a class="title">Email</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '70' }" @mouseover="headerHover('70')" :to="{ path: '/customize_user_request_access/customize_whatsapp_settings'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_whatsapp_settings==true" title="WhatsApp">
                            <a class="title">WhatsApp</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '146' }" @mouseover="headerHover('146')" :to="{ path: '/customize_user_request_access/customize_acs_gateway'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_acs_gateway==true" title="Acs Gateway">
                            <a class="title">Acs Gateway</a>
                          </li>
                        </router-link>
                        
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '69' }" @mouseover="headerHover('69')" :to="{ path: '/external_integration/payment_gateway_settings'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_gateway_settings==true" title="Payment Gateway">
                            <a class="title">Payment Gateway</a>

                          </li>
                        </router-link>                        
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '71' }" @mouseover="headerHover('71')" :to="{ path: '/external_integration/ftp'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ftp==true" title="FTP">
                            <a class="title">FTP</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '72' }" @mouseover="headerHover('72')" :to="{ path: '/external_integration/googledrive'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.googledrive==true" title="Google Drive">
                            <a class="title">Google Drive</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '73' }" @mouseover="headerHover('73')" :to="{ path: '/external_integration/amazon_s3'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.amazon_s3==true" title="S3">
                            <a class="title">S3</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '74' }" @mouseover="headerHover('74')" :to="{ path: '/external_integration/one_signal'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PaymentCollection==true" title="One Signal">
                            <a class="title">One Signal</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '75' }" @mouseover="headerHover('75')" :to="{ path: '/external_integration/web_hooks'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.web_hooks==true" title="WebHooks">
                            <a class="title">WebHooks</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '76' }" @mouseover="headerHover('76')" :to="{ path: '/external_integration/quickbooks'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.quickbooks==true && headerMenuDetails.edition == 'isp'" title="QuickBooks">
                            <a class="title">QuickBooks</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '77' }" @mouseover="headerHover('77')" :to="{ path: '/external_integration/customize_iptv'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_iptv==true && headerMenuDetails.edition == 'isp'" title="IPTV">
                            <a class="title">IPTV</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '78' }" @mouseover="headerHover('78')" :to="{ path: '/external_integration/customize_ipphone'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ipphone==true && headerMenuDetails.edition == 'isp'" title="Voice Phone">
                            <a class="title">Voice Phone</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '79' }" @mouseover="headerHover('79')" :to="{ path: '/external_integration/customize_ott'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ott==true && headerMenuDetails.edition == 'isp'" title="OTT">
                            <a class="title">OTT</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '134' }" @mouseover="headerHover('134')" :to="{ path: '/external_integration/customize_ivr'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_ivr==true && headerMenuDetails.edition == 'isp'" title="IVR">
                            <a class="title">IVR</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '135' }" @mouseover="headerHover('135')" :to="{ path: '/external_integration/customize_enach'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_enach==true && headerMenuDetails.edition == 'isp'" title="Enach">
                            <a class="title">Enach</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '136' }" @mouseover="headerHover('136')" :to="{ path: '/external_integration/customize_aadhaarkyc'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customize_aadhaarkyc==true && headerMenuDetails.edition == 'isp'" title="Aadhaarkyc">
                            <a class="title">Aadhaar KYC</a>

                          </li>
                        </router-link>
                      </div>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Inventory==true && headerMenuDetails.edition == 'isp'">
                        <li class="list-each list-head head-new list-each-i">
                          <span>Inventory</span>
                        </li>
                        <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_dashboard==true">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '81' }" @mouseover="headerHover('81')" :to="{ path: '/inventory/inventory_dashboard'}">
                            <li class="list-each-i" title="Inventory Dashboard">
                              <a class="title">Inventory Dashboard</a>

                            </li>
                          </router-link>
                        </div>
                        <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.stock==true">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '82' }" @mouseover="headerHover('82')" :to="{ path: '/inventory/stock'}">
                            <li class="list-each-i" title="Stock Overview">
                              <a class="title">Stock Overview</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '83' }" @mouseover="headerHover('83')" :to="{ path: '/inventory/goods_in_use'}">
                            <li class="list-each-i" title="Stock Details">
                              <a class="title">Stock Details</a>

                            </li>
                          </router-link>
                        </div>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '84' }" @mouseover="headerHover('84')" :to="{ path: '/inventory/inventory_items'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_items==true" title="Items">
                            <a class="title">Items</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '85' }" @mouseover="headerHover('85')" :to="{ path: '/inventory/vendor_management'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.vendor_management==true" title="Vendor Management">
                            <a class="title">Vendor Management</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '86' }" @mouseover="headerHover('86')" :to="{ path: '/inventory/manage_locations'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.manage_locations==true" title="Manage Locations">
                            <a class="title">Manage Locations</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '87' }" @mouseover="headerHover('87')" :to="{ path: '/inventory/inventory_configuration'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_configuration==true" title="Configuration">
                            <a class="title">Configuration</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '88' }" @mouseover="headerHover('88')" :to="{ path: '/inventory/inventory_approval'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.inventory_approval==true" title="Approval">
                            <a class="title">Approval</a>

                          </li>
                        </router-link>
                      </div>
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Discount==true">
                        <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Discount==true">
                          <span>Discount Voucher</span>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '147' }" @mouseover="headerHover('147')" :to="{ path: '/discount'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Discount==true">
                            <a class="title">Discount Coupon Management</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '148' }" @mouseover="headerHover('148')" :to="{ path: '/discount/discount_configuration'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.discount_configuration_list==true">
                            <a class="title">Discount Configuration</a>
                          </li>
                        </router-link>
                      </div>
                    </ul>
                  </li>

                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.edition == 'isp'">
                        <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.get_all_leads==true || headerMenuDetails.permissions.lead_classification==true || headerMenuDetails.permissions.completed_leads==true || headerMenuDetails.permissions.dropped_leads==true || headerMenuDetails.permissions.assign_leads==true) && headerMenuDetails.edition=='isp'">
                          <span>Leads</span>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '140' }" @mouseover="headerHover('140')" :to="{ path: '/lead_management/lead_dashboard'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_dashboard==true" title="Lead Dashboard">
                            <a class="title">Lead Dashboard</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '89' }" @mouseover="headerHover('89')" :to="{ path: '/lead_management'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.get_all_leads==true" title="Lead Management">
                            <a class="title">Lead Management</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '90' }" @mouseover="headerHover('90')" :to="{ path: '/lead_management/lead_classification'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_classification==true" title="Lead Configuration">
                            <a class="title">Lead Configuration</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '91' }" @mouseover="headerHover('91')" :to="{ path: '/lead_management/completed_leads'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.completed_leads==true" title="Completed">
                            <a class="title">Completed</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '138' }" @mouseover="headerHover('138')" :to="{ path: '/lead_management/tat_leads'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.tat_leads==true" title="TAT Leads">
                            <a class="title">TAT Leads</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '92' }" @mouseover="headerHover('92')" :to="{ path: '/lead_management/dropped_leads'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dropped_leads==true" title="Dropped">
                            <a class="title">Dropped</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '93' }" @mouseover="headerHover('93')" :to="{ path: '/lead_management/assign_leads'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.assign_leads==true" title="Assign Leads">
                            <a class="title">Assign Leads</a>

                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '139' }" @mouseover="headerHover('139')" :to="{ path: '/lead_management/lead_sla'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.lead_sla==true" title="Lead SLA">
                            <a class="title">Lead SLA</a>
                          </li>
                        </router-link>
                        <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Iptv==true && headerMenuDetails.edition == 'isp'">
                          <li class="list-each list-head head-new list-each-i">
                            <span>IPTV</span>
                          </li>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '94' }" @mouseover="headerHover('94')" :to="{ path: '/iptv/set_top_box'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.set_top_box==true" title="Set Top Box">
                              <a class="title">Set Top Box</a>

                            </li>
                          </router-link>
                        </div>
                        <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Ipphone==true">
                          <li class="list-each list-head head-new list-each-i">
                            <span>Voice Phone</span>
                          </li>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '95' }" @mouseover="headerHover('95')" :to="{ path: '/ipphone/set_top_phone'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.set_top_phone==true">
                              <a>Voice Phone</a>
                            </li>
                          </router-link>
                        </div>

                      </div>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="jaze-topbar-header header-new"  @mouseleave="hide" @mouseover="viewHeaderMenuDatafn('routers')">
            <span class="jaze-topbar-title title-new">
              <span v-if="headerMenuDetails.edition=='isp'"> Routers </span>
              <span v-else> NAS </span>
              <span> & Logs </span>
              <svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="top-icon">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" class="top-icon-inner"></path>
              </svg>
            </span>
            <ul class="jaze-main-menu" @mouseover="viewHeaderMenuDatafn('routers')" :style='(viewHeaderMenuData == "routers")? "display:flex":"display:none"'>
              <li class="jaze-main-menu-col col-new">
                <ul class="col-content col-content-new">
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.AccessPoints==true">
                        <span v-if="headerMenuDetails.edition=='isp'"> Router Config </span>
                        <span v-else> NAS Config </span>
                      </li>
                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.AccessPoints==true">
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '96' }" @mouseover="headerHover('96')" :to="{ path: '/routers'}">
                          <li class="list-each-i" :title="headerMenuDetails.edition=='isp' ? 'Routers': 'NAS'">

                            <a v-if="headerMenuDetails.edition=='isp'">Routers</a>
                            <a v-else>Routers</a>

                          </li>
                        </router-link>
                        <div v-if="headerMenuDetails.edition=='isp'">
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '97' }" @mouseover="headerHover('97')" :to="{ path: '/ip_ranges'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ip_ranges==true" title="IP Management">
                              <a>IP Management</a>

                            </li>
                          </router-link>
                          <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '98' }" @mouseover="headerHover('98')" :to="{ path: '/cpe_device'}">
                            <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.cpe_device==true" title="OLT and CPE Devices">
                              <a>OLT and CPE Devices</a>

                            </li>
                          </router-link>
                        </div>
                        <div v-else-if="headerMenuDetails.unifiEnabled">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.wlan_config==true">
                            <a href="/access_points/wlan_config">
                              <span class="title">WLAN Profiles</span>
                            </a>
                          </li>
                        </div>
                      </div>

                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Acs==true && headerMenuDetails.acsConfig==true">
                        <li class="list-each list-head head-new list-each-i">
                         TR-069 Config 
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '144' }" @mouseover="headerHover('144')" :to="{ path: '/acs'}">
                          <li class="list-each-i" :title="'Dashboard'">
                            <a>Dashboard</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '145' }" @mouseover="headerHover('145')" :to="{ path: '/acs/acs_devices'}">
                          <li class="list-each-i" :title="'Devices'">
                            <a>Devices</a>
                          </li>
                        </router-link>
                      </div>

                      <div v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Analytics==true && !(headerMenuDetails.edition == 'isp' && headerMenuDetails.currentTotalUsers > 300)">
                        <li class="list-each list-head head-new list-each-i">
                          <span>Analytics</span>
                        </li>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '99' }" @mouseover="headerHover('99')" :to="{ path: '/analytics'}">
                          <li class="list-each-i">
                            <a class="title">Analytics</a>
                          </li>
                        </router-link>
                      </div>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.CaptivePortal==true || headerMenuDetails.permissions.user_portal==true || headerMenuDetails.permissions.CpConfiguration==true)">
                        <span>Captive Portal Settings</span>
                      </li>
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_networks==true && headerMenuDetails.jazePlatform == 'hardware' && 0">
                        <a href="/CpConfiguration/captive_portal_networks">
                          <span class="title">Captive Portal Networks</span>
                        </a>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '100' }" @mouseover="headerHover('100')" :to="{ path: '/captive_portal_configuration'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.CpConfiguration==true" title="Configuration">
                          <a class="title">Captive Portal Configuration</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '101' }" @mouseover="headerHover('101')" :to="{ path: '/captive_portal_settings/enable_captive_portal'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.enable_captive_portal==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.Auth !=undefined && headerMenuDetails.Auth.User.Account.parent_id == null && headerMenuDetails.chilliEnabled">
                          <a class="title">Captive Portal</a>
                        </li>
                      </router-link>
                      <!-- <router-link :class="{ 'jazeLink': hoverClass == '102' }" @mouseover="headerHover('102')" :to="{ path: '/captive_portal_settings/blocked_macs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.blocked_macs==true" title="Blocked MACs">
                          <a class="title">Blocked MACs</a>

                        </li>
                      </router-link>-->
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '103' }" @mouseover="headerHover('103')" :to="{ path: '/captive_portal_settings/wireless_instruction'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.customization_wireless_instruction==true && headerMenuDetails.edition === 'campus'">
                          <a>Customize instructions to connect</a>
                        </li>
                      </router-link> 
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.access_control==true && headerMenuDetails.edition === 'cloud' && headerMenuDetails.unifiEnabled">
                        <a href="/captive_portal_access_control">
                          <span class="title">Access Control</span>
                        </a>
                      </li>
                      <!-- <router-link :class="{ 'jazeLink': hoverClass == '104' }" @mouseover="headerHover('104')" :to="{ path: '/captive_portal_settings'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_preview==true">
                          <a>
                            <span class="title">Customize Captive Portal</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link :class="{ 'jazeLink': hoverClass == '105' }" @mouseover="headerHover('105')" :to="{ path: '/captive_portal_settings/user_portal'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_portal==true" title="Customize User Portal">
                          <a class="title">Customize User Portal</a>

                        </li>
                      </router-link> -->
                      <!-- <router-link :class="{ 'jazeLink': hoverClass == '106' }" @mouseover="headerHover('106')" :to="{ path: '/captive_portal_settings/targeted_ads'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.targeted_ads==true" title="Targeted Ads">
                          <a class="title">Targeted Ads</a>

                        </li>
                      </router-link> -->
                      <!-- <router-link :class="{ 'jazeLink': hoverClass == '107' }" @mouseover="headerHover('107')" :to="{ path: '/customize_user_request_access'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.CaptivePortal==true" title="User Registration Config">
                          <a class="title">User Registration Config</a>

                        </li>
                      </router-link> -->
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_website_logging==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.edition == 'campus'">
                        <a href="/captive_portal_settings/captive_portal_website_logging">
                          <span class="title">Website Logging</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && (headerMenuDetails.permissions.Logs==true || headerMenuDetails.permissions.event_data==true || headerMenuDetails.permissions.user_logs==true || headerMenuDetails.permissions.sms_logs==true || headerMenuDetails.permissions.payment_logs==true || headerMenuDetails.permissions.nat_logs==true)">
                        <span>Logs</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '108' }" @mouseover="headerHover('108')" :to="{ path: '/logs/event_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.event_data==true" title="Event Logs">
                          <a>Event Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '109' }" @mouseover="headerHover('109')" :to="{ path: '/logs/user_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.user_logs==true" title="User Logs">
                          <a>User Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '110' }" @mouseover="headerHover('110')" :to="{ path: '/logs/sms_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.sms_logs==true" title="SMS Logs">
                          <a>Sms Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '111' }" @mouseover="headerHover('111')" :to="{ path: '/logs/email_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.email_logs==true" title="Email Logs">
                          <a>Email Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '149' }" @mouseover="headerHover('149')" :to="{ path: '/logs/whatsapp_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.whatsapp_logs==true" title="Whatsapp Logs">
                          <a>Whatsapp Logs</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '112' }" @mouseover="headerHover('112')" :to="{ path: '/logs/payment_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment_logs==true" title="Payment logs">
                          <a>Payment Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '113' }" @mouseover="headerHover('113')" :to="{ path: '/logs/nat_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.nat_logs==true">
                          <a>NAT logs</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '114' }" @mouseover="headerHover('114')" :to="{ path: '/logs/webhook_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.webhook_logs==true" title="Webhook logs">
                          <a>Webhook logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '115' }" @mouseover="headerHover('115')" :to="{ path: '/logs/franchise_payment_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.franchise_payment_logs==true && (headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.Dashboard.parentFranchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no') || ((headerMenuDetails.franchiseHead == 'yes' && headerMenuDetails.Auth.Dashboard.franchiseExclude == 'no')))" title="Franchise External Payment Logs">
                          <a>Franchise External Payment Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '116' }" @mouseover="headerHover('116')" :to="{ path: '/logs/pms_logs'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.pms_logs==true && headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.edition != 'isp'" title="PMS Logs">
                          <a>PMS Logs</a>

                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '117' }" @mouseover="headerHover('117')" :to="{ path: '/logs/release_notes'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.PaymentCollection==true" title="Release notes">
                          <a>Release notes</a>

                        </li>
                      </router-link>
                      <div v-if="headerMenuDetails.jazePlatform == 'hardware'">
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '118' }" @mouseover="headerHover('118')" :to="{ path: '/logs/dhcp_leases'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dhcp_leases==true">
                            <a>DHCP Leases</a>
                          </li>
                        </router-link>
                        <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '119' }" @mouseover="headerHover('119')" :to="{ path: '/logs/captive_portal_logs'}">
                          <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.captive_portal_logs==true && headerMenuDetails.chilliEnabled">
                            <a>Captive portal Logs</a>
                          </li>
                        </router-link>
                      </div>
                    </ul>
                    <ul class="jaze-content-list-ul">
                      <li v-if="headerMenuDetails.jazePlatform=='cloud' && headerMenuDetails.Auth.User.Account.parent_id == null" class="list-each list-head head-new list-each-i">
                        <span>Statistics</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '151' }" @mouseover="headerHover('151')" :to="{ path: '/statistics/status'}">
                        <li class="list-each-i" v-if="headerMenuDetails.jazePlatform=='cloud' && headerMenuDetails.Auth.User.Account.parent_id == null">
                          <a>
                            <span class="title">Status</span>
                          </a>
                        </li>
                      </router-link>
                    </ul>

                  </li>
                  <li class="jaze-content-list">
                    <ul class="jaze-content-list-ul">
                      <div v-if="headerMenuDetails.Auth !=undefined && headerMenuDetails.authAccType=='full'">
                        <li class="list-each list-head head-new list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment==true">
                          <span>Payment</span>
                        </li>
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.payment==true">
                          <a href="/payment">
                            <span class="title">Payment Information</span>
                          </a>
                        </li>
                      </div>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="jaze-topbar-header header-new"  @mouseleave="hide" @mouseover="viewHeaderMenuDatafn('network')" v-if="headerMenuDetails.jazePlatform == 'hardware' && headerMenuDetails.Auth !=undefined && (headerMenuDetails.Auth.User.Account.parent_id == null || headerMenuDetails.Auth.User.Account.parent_id == '')">
            <span class="jaze-topbar-title title-new">
              Network & Miscellaneous
              <svg width="4.7" height="8" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="top-icon">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#fff" class="top-icon-inner"></path>
              </svg>
            </span>
            <ul class="jaze-main-menu" @mouseover="viewHeaderMenuDatafn('network')" :style='(viewHeaderMenuData == "network")? "display:flex":"display:none"'>
              <li class="jaze-main-menu-col col-new">
                <ul class="col-content col-content-new">
                  <li class="jaze-content-list" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.NetworkSettings==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i">
                        <span>Network Settings</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '120' }" @mouseover="headerHover('120')" :to="{ path: '/network_settings/index'}">
                        <li class="list-each-i">
                          <a>Interface</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '121' }" @mouseover="headerHover('121')" :to="{ path: '/network_settings/vlan'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.vlan==true">
                          <a>VLANs</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '122' }" @mouseover="headerHover('122')" :to="{ path: '/network_settings/dhcp'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.dhcp==true">
                          <a>DHCP</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '123' }" @mouseover="headerHover('123')" :to="{ path: '/network_settings/routing'}">
                        <li class="list-each-i">
                          <a>Routing &amp; NAT</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '124' }" @mouseover="headerHover('124')" :to="{ path: '/network_settings/snmp'}">
                        <li class="list-each-i">
                          <a>SNMP</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                  <li class="jaze-content-list" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.Diagnostics==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i">
                        <span>Diagnostics</span>
                      </li>
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.arp_table==true && 0">
                        <a href="/diagnostics/arp_table">
                          <span class="title">ARP Table</span>
                        </a>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '125' }" @mouseover="headerHover('125')" :to="{ path: '/diagnostics/ping'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.ping==true">
                          <a>Ping</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '126' }" @mouseover="headerHover('126')" :to="{ path: '/diagnostics/trace_route'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.trace_route==true">
                          <a>Traceroute</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '127' }" @mouseover="headerHover('127')" :to="{ path: '/diagnostics/system_status'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_status==true">
                          <a>System Status</a>
                        </li>
                      </router-link>
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_logs==true && 0">
                        <a href="/diagnostics/system_logs">
                          <span class="title">System Logs</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li class="jaze-content-list" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.SystemTools==true && headerMenuDetails.jazePlatform=='hardware' && (headerMenuDetails.parent == '' || headerMenuDetails.parent == null)">
                    <ul class="jaze-content-list-ul">
                      <li class="list-each list-head head-new list-each-i">
                        <span>System Tools</span>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '150' }" @mouseover="headerHover('150')" :to="{ path: '/system_tools/index'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.system_tools==true">
                          <a>
                            <span class="title">System Tools</span>
                          </a>
                        </li>
                      </router-link>
                      <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.time_settings==true && 0">
                        <a href="/system_tools/time_settings">
                          <span class="title">Time Settings</span>
                        </a>
                      </li>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '128' }" @mouseover="headerHover('128')" :to="{ path: '/system_tools/factory_defaults'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.factory_defaults==true">
                          <a>Factory Defaults</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '129' }" @mouseover="headerHover('129')" :to="{ path: '/system_tools/backup'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.backup==true">
                          <a>Backup/Restore</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '130' }" @mouseover="headerHover('130')" :to="{ path: '/system_tools/reboot'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.reboot==true">
                          <a>Reboot System</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '131' }" @mouseover="headerHover('131')" :to="{ path: '/system_tools/halt'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.halt==true">
                          <a>Shutdown System</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '132' }" @mouseover="headerHover('132')" :to="{ path: '/system_tools/update'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.update==true">
                          <a>Update</a>
                        </li>
                      </router-link>
                      <router-link @click="hide" :class="{ 'jazeLink': hoverClass == '133' }" @mouseover="headerHover('133')" :to="{ path: '/system_tools/certificates'}">
                        <li class="list-each-i" v-if="headerMenuDetails.permissions != undefined && headerMenuDetails.permissions.certificates==true">
                          <a class="title">Certificates</a>
                        </li>
                      </router-link>
                    </ul>
                  </li>

                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-------- END - Mobile Menu List ---------->
  </div>
</template>
<script>

import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";
import { ref,computed,watch,onMounted } from 'vue'
export default {
  props: ["mobileShow"],
  setup(props) {
    console.log(props)
    const layoutStore = uselayoutStore()
    const { headerDetails } = storeToRefs(layoutStore)
    const toggleData=ref([]);
    const mobileShowVal=ref(false);
    const viewHeaderMenuData=ref("");
    const headerMenuDetails=ref([]);
    const backUpNotStoring=ref(null);
    const contact=ref(null);
    const hoverClass=ref("");
   const headerDetailsGettera= computed(() =>headerDetails.value);
    watch(headerDetailsGettera,() => {
     headerMenuDetails.value=headerDetailsGettera.value;
    });
    const getHeaderDetails = () => {
       if (props.mobileShow == "mobileShow") {
        mobileShowVal.value = true;
      } else {
        mobileShowVal.value = false;
      }
      if (
        props.mobileShow == null ||
        props.mobileShow == undefined ||
        props.mobileShow == ""
      ) {
        mobileShowVal.value = true;
      }
      if (Object.keys(headerDetailsGettera.value).length == 0) {
        //this.$store.dispatch("fetchHeaderDetails");
        layoutStore.fetchHeaderDetails(5)
      } else {
        headerMenuDetails.value =headerDetailsGettera.value;
      }
      for (var i = 0; i <= 23; i++) {
        toggleData.value.push("");
      }
      }
      onMounted(() => {
      getHeaderDetails()
    })
    const headerHover = (value) => {
     hoverClass.value=value;
    }
    const viewHeaderMenuDatafn = (value) => {
     viewHeaderMenuData.value=value;
    }
     const hide = () => {
     viewHeaderMenuData.value="";
    }
    const sideBarShow=()=>{
      setTimeout(() => {
        mobileShowVal.value=!mobileShowVal.value
      }, 100);
    }
     const viewTogglefn = (value,key) => {
     if (toggleData.value[key] == value) {
        toggleData.value.splice(key, 1, "");
      } else {
        toggleData.value.splice(key, 1, value);
      }
    }
    return { viewTogglefn,headerHover,hide,viewHeaderMenuDatafn,layoutStore, headerDetailsGetter: headerDetails,toggleData,mobileShowVal,viewHeaderMenuData,
     headerMenuDetails,backUpNotStoring,contact,hoverClass,sideBarShow}
  },
 
  
  
};
</script>
